const useGetHomePageData = ({ immediate }: { immediate?: boolean; cached?: true } = {}) => {
	const { data: appInit } = useAppInitData();

	return useAsyncFetch({
		path: "/rest/page/home/",
		method: "get",
		options: {
			immediate,
			watch: [() => appInit.value?.isGuest],
			transform: (data) => ({
				seo: data.seo,
				jackpots: data.payload.jackpots,
				banners: data.payload.banners,
				promoBanners: data.payload.promoBanners,
				promotions: data.payload.promotions,
				infoPlate: data.payload.infoPlate,
				games: {
					jackpot: data.payload?.games?.issues?.jackpot?.games || [],
					popularGames: data.payload?.games?.issues?.["popular-games"]?.games || [],
					newGames: data.payload?.games?.issues?.["new-games"]?.games || [],
					fishing: data.payload?.games?.issues?.fishing?.games || []
				}
			})
		}
	});
};

export default useGetHomePageData;
